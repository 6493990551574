import {
	AVAILABLE_LOCALES,
	useLocalization,
	useLocalizedRoutes
} from '@planity/localization';
import { DistrictsAround } from './districts_around';
import React, { Fragment, forwardRef, useMemo } from 'react';
import { safeRead } from '@planity/helpers';
import { Localize } from '@planity/components';
import { AlgoliaSearch } from '../data';
import { BottomLinks } from '../ui';
import { Highlight } from '../category_page/highlight';
import { TextSection } from '../category_page/text_section';
import { CitiesSelection } from './cities_selection';
import credentials from '@planity/credentials';
import styles from './content.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const Content = forwardRef(
	({ isLoading, location, search, className }, ref) => {
		const { routes } = useLocalizedRoutes();
		const { language } = useLocalization();
		const hasPagination = /\/page-\d+/.test(location.pathname);
		const attributesToRetrieve = hasPagination
			? ['*'].concat(['-text'])
			: ['*'];
		useStyles(styles);
		const classes = classNames.bind(styles)({
			content: true,
			[className]: className
		});

		const basePathWithoutLocaleAndPage = useMemo(() => {
			// Equivalent to /^\/?fr-FR|^\/?de-DE/gm
			// Updated regex to match everything up to /page-X
			const localeMatcher = new RegExp(
				AVAILABLE_LOCALES.map(locale => `^/?${locale}`).join('|') +
					'|/page-\\d+',
				'gm'
			);
			return location.pathname.replace(localeMatcher, '').toLowerCase();
		}, [location]);

		return (
			<AlgoliaSearch
				attributesToHighlight={[]}
				attributesToRetrieve={attributesToRetrieve}
				filters={`objectID:"${basePathWithoutLocaleAndPage}"`}
				index={credentials.SEO_DATA}
				localizeResults
			>
				{({ data }) => {
					const record = data && data[0];
					const seoData =
						(record && record.sections) || (record ? [record] : null);
					const baseSearch = [
						'parentCategory',
						'category',
						'parentPlace',
						'place'
					].reduce((all, key) => {
						if (search[key]) all[key] = search[key];
						return all;
					}, {});

					const isParisBeautySalon =
						baseSearch?.parentCategory?.objectID === 'beauty_salon' &&
						!baseSearch.category &&
						baseSearch?.parentPlace?.objectID === 'hw7' &&
						!baseSearch.place;

					const isCitiesSelectionVisible =
						!baseSearch.place &&
						baseSearch.parentCategory &&
						baseSearch.parentPlace;

					if (!(seoData && !isLoading)) {
						return null;
					}
					const { highlight, sections } = record.text || {};
					const isDistrict = baseSearch?.place?.type === 'district';

					return (
						<div className={classes}>
							<div ref={ref}>
								{isCitiesSelectionVisible && (
									<CitiesSelection baseSearch={baseSearch} />
								)}
								{seoData.map(({ name, places, refiners }) => (
									<Fragment key={name}>
										{isDistrict && (
											<DistrictsAround
												baseSearch={baseSearch}
												getRefiner={getRefiner}
												isLoading={isLoading}
											/>
										)}
										<Localize
											text={
												isParisBeautySalon
													? 'category.relatedSearchesParisBeautySalon'
													: 'category.relatedSearches'
											}
										>
											{title => (
												<BottomLinks
													className={styles.linkWrapper}
													getPath={object =>
														routes.catchAll({
															search: getRefiner(baseSearch, object, 'place')
														})
													}
													getText={object => {
														if (!object) return null;
														if (isParisBeautySalon) {
															return `${name} ${
																object.preposition
															} ${object.name.replace(/(er|ème)$/, '')}`;
														}
														return `${name} ${object.preposition} ${object.name}`;
													}}
													links={
														places?.children?.length
															? places?.children
															: places?.nearby
													}
													title={title}
												/>
											)}
										</Localize>
										<Localize
											text={
												isParisBeautySalon
													? 'category.relatedSearchesAltParisBeautySalon'
													: 'category.relatedSearchesAlt'
											}
										>
											{title => {
												refiners?.forEach(refiner => {
													// Only for French professions
													if (
														language === 'fr' &&
														refiner.objectID === 'hair_care'
													) {
														refiner.name = 'Salon de coiffure';
													}
												});
												return (
													<BottomLinks
														className={styles.linkWrapper}
														getPath={object =>
															routes.catchAll({
																search: getRefiner(
																	baseSearch,
																	object,
																	'category'
																)
															})
														}
														getText={object => {
															const place =
																baseSearch.place || baseSearch.parentPlace;
															if (!object || !place) return null;
															return `${
																object.seoName ? object.seoName : object.name
															} ${place.preposition} ${place.name}`;
														}}
														links={refiners}
														title={title}
													/>
												);
											}}
										</Localize>
									</Fragment>
								))}
							</div>
							{!hasPagination && (
								<>
									<Highlight
										className={styles.textHighlight}
										highlight={highlight}
										isInverted
										style={{ fontSize: '18px', lineHeight: '27px' }}
									/>
									{(sections || []).map((text, i) => (
										<TextSection
											className={styles.textSection}
											isDark={highlight ? i % 2 !== 0 : i % 2 === 0}
											key={i}
											{...text}
										/>
									))}
								</>
							)}
						</div>
					);
				}}
			</AlgoliaSearch>
		);
	}
);

function getRefiner(baseSearch, object, name) {
	let key;
	let parentId = null;

	if (name === 'category') {
		if (object.depth === 1) {
			return {
				// Need to set both parentCategory and category for link to profession+city
				...baseSearch,
				parentCategory: object,
				category: null
			};
		} else {
			key = 'category';
			parentId = safeRead(baseSearch, ['parentCategory', 'objectID']);
		}
	} else {
		if (
			object.depth === 1 ||
			(object.depth === 2 && object.type === 'locality')
		) {
			key = 'parentPlace';
		} else {
			key = 'place';
			parentId = safeRead(baseSearch, ['parentPlace', 'objectID']);
		}
	}
	return {
		...baseSearch,
		[key]: parentId ? { ...object, parentId } : object
	};
}
