//@ts-check
import React, { useEffect, useRef, useState } from 'react';
import { Address, Map, OpeningHours } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { WithScreenDimensions, breakpoints, Match } from '@planity/theme';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	getHumanCalendars,
	invokeLambda,
	parseOpeningHours
} from '@planity/helpers';
import {
	BusinessDescription,
	BusinessTags,
	BusinessCollaborators
} from '@planity/ui';
import { HiddenMap } from '@planity/ui/uikit/map';
import classNames from 'classnames/bind';
import styles from './about.module.scss';

export function About({ business, className, crumbs, parentPlace, place }) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		map: true,
		[className]: className !== undefined
	});

	const map = useRef(null);
	const [width, setWidth] = useState(map?.current?.offsetWidth || 760);
	const [isMapShowed, setIsMapShowed] = useState(false);
	const openingHours = parseOpeningHours(business.openingHours, {
		convertToMinutes: false
	});
	const humanCalendars = getHumanCalendars(business?.calendars);
	const humanCalendarsWithPicture = Object.values(humanCalendars)?.filter(
		x => x.picture
	);
	const forbidCalendarSelection = !!business?.settings?.forbidCalendarSelection;
	const hasHumanCalendarsToDisplay = forbidCalendarSelection
		? !!humanCalendarsWithPicture?.length
		: !!Object.values(humanCalendars)?.length;

	useEffect(() => {
		if (!map.current && !map.current.offsetWidth) return;
		const handleResize = () => {
			setWidth(map.current.offsetWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [map]);

	//TODO : remove function after Mapbox usage test
	const onShowMapClick = async () => {
		setIsMapShowed(true);
		try {
			await invokeLambda('countMapClicks', {
				page: 'business_page'
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<WithScreenDimensions>
			{({ windowWidth }) => {
				const mapHeight = windowWidth >= breakpoints.tablet ? 500 : 200;
				return (
					<Match query={breakpoints.simpleDesktopQuery}>
						{isDesktop => (
							<div className={classes}>
								<div className={styles.main} ref={map}>
									{!!business?.location && (
										<div>
											<h2 className={styles.title}>{t('business.map')}</h2>
											<span className={styles.address}>
												<Address
													{...business.address}
													location={business.location}
													title={business.name}
													shouldOpenMap
												/>
											</span>
											<div className={styles.card}>
												{isMapShowed ? (
													<Map
														business={business}
														height={mapHeight}
														showInteractiveMap
														width={isDesktop ? width : windowWidth}
													/>
												) : (
													<HiddenMap
														setIsMapShowed={onShowMapClick}
														height={mapHeight}
													/>
												)}
											</div>
										</div>
									)}
									{!isDesktop && openingHours && (
										<OpeningHours
											business={business}
											openingHours={openingHours}
										/>
									)}
									{hasHumanCalendarsToDisplay && (
										<BusinessCollaborators {...business} />
									)}
									<BusinessDescription {...business} />
									<BusinessTags
										business={business}
										crumbs={crumbs}
										parentPlace={parentPlace}
										place={place}
									/>
								</div>
							</div>
						)}
					</Match>
				);
			}}
		</WithScreenDimensions>
	);
}
