import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './suggestions.module.scss';
import {
	useTranslation,
	useLocalization,
	DEFAULT_LOCALE
} from '@planity/localization';
import { Container, Title } from '@planity/ui';
import AlgoliaSearch from '@planity/components/algolia_search';
import credentials from '@planity/credentials';

const CATEGORIES = ['hair_care', 'nails', 'barber_shop', 'beauty_salon'];

/** Show only well-being feature if process.env.WELL_BEING is true */
if (process.env.WELL_BEING) {
	CATEGORIES.push(
		'hypnotherapeute',
		'reflexologue',
		'naturopathe',
		'sophrologue'
	);
}

const NB_ITEMS_CITIES = 10;

const orderAlphabetically = (a, b) =>
	a.name > b.name ? 1 : a.name < b.name ? -1 : 0;

export function Suggestions() {
	useStyles(styles);
	const { t } = useTranslation();
	const { countryCode, locale, language } = useLocalization();
	const classes = classNames.bind(styles)({ suggestions: true });
	const country = t(`countries.${countryCode}.name`);

	return (
		<div className={classes}>
			<Container size='large' noStupidMarginBottom>
				<Title
					label={t('landing.suggestions.everywhere', { country })}
					title={t('landing.suggestions.findBusiness', { country })}
				/>
				<div className={styles.quickFind}>
					<AlgoliaSearch
						attributesToHighlight={[]}
						filters={`objectID:/${locale}`}
						index={credentials.SEO_DATA}
						localizeResults
						language={language}
					>
						{({ data }) => {
							if (!data || data.length === 0) return null;

							const beautySalonCategory = data[0].children.find(
								category => category.categoryId === 'beauty_salon'
							);

							const massageCategory = beautySalonCategory?.children.find(
								child => child.slug === 'massage'
							);

							return (
								<div className={styles.categories}>
									{/* Processing main categories */}
									{data[0].children
										.filter(category =>
											CATEGORIES.includes(category.categoryId)
										)
										.map((category, id) => (
											<div className={styles.category} key={id}>
												<p>
													{t(
														`suggestions.categories.${category.categoryId}.name`,
														{ country }
													)}
												</p>
												<h3>
													{t(
														`suggestions.categories.${category.categoryId}.subtitle`,
														{ country }
													)}
												</h3>
												<div className={styles.cities}>
													{category.children
														?.filter(city => city.type === 'locality')
														.sort(orderAlphabetically)
														.slice(0, NB_ITEMS_CITIES)
														.map(city => (
															<div
																key={`${category.objectID}-${city.objectID}`}
															>
																<a
																	href={`${
																		locale !== DEFAULT_LOCALE ? locale : ''
																	}${category.slug}/${city.slug}`}
																>
																	{city.name}
																</a>
															</div>
														))}
												</div>
											</div>
										))}

									{/* Separate block for "massage" */}
									{beautySalonCategory && massageCategory && (
										<div className={styles.category}>
											<p>
												{t('suggestions.categories.massage.name', { country })}
											</p>
											<h3>
												{t('suggestions.categories.massage.subtitle', {
													country
												})}
											</h3>
											<div className={styles.cities}>
												{massageCategory.children
													?.sort(orderAlphabetically)
													.slice(0, NB_ITEMS_CITIES)
													.map(city => (
														<div
															key={`${massageCategory.objectID}-${city.slug}`}
															className={styles.city}
														>
															<a
																href={`${
																	locale !== DEFAULT_LOCALE ? locale : ''
																}${beautySalonCategory.slug}/${
																	massageCategory.slug
																}/${city.slug}`}
															>
																{city.name}
															</a>
														</div>
													))}
											</div>
										</div>
									)}
								</div>
							);
						}}
					</AlgoliaSearch>
				</div>
			</Container>
		</div>
	);
}
