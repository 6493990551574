import { DEFAULT_LOCALE } from '@planity/localization';
import { actions, assign, createMachine } from 'xstate';
import {
	invokePhoneNumberVerificationRecaptchaProtected,
	checkingOtherProviders,
	createUserInDatabase,
	createUserWithEmailAndPassword,
	fetchDifferentAccounts,
	mergeEmailAndPasswordWithProvider,
	retrieveProData,
	retrieveUserData,
	sendPasswordResetEmail,
	setUserPhoneInDatabase,
	signInWithEmailAndPassword,
	signInWithProvider,
	signInWithProviderAndMergeAccount,
	updateMissingFields
} from './auth_methods';
import {
	EMAIL_IN_USE_ERROR,
	INVALID_EMAIL_ERROR,
	INVALID_PASSWORD_ERROR,
	MISSING_EMAIL_ERROR,
	MISSING_FIRST_NAME_ERROR,
	MISSING_LAST_NAME_ERROR,
	MISSING_PASSWORD_ERROR,
	MISSING_PHONE_NUMBER_ERROR,
	MUST_ACCEPT_CONDITIONS_ERROR,
	INVALID_FORMAT_ERROR,
	MISSING_ALL_INPUTS_ERROR,
	WRONG_PASSWORD_ERROR,
	USER_NOT_FOUND_ERROR,
	TOO_MANY_ATTEMPTS_ERROR,
	WRONG_PASSWORD_ERROR_WIDGET
} from './errors';
import {
	BACK_TO_IDLE,
	CGU_ACCEPTANCE_CHANGE,
	CHECK_SUBSCRIPTION_STEP,
	EMAIL_CHANGE,
	FIRST_NAME_CHANGE,
	LAST_NAME_CHANGE,
	PASSWORD_CHANGE,
	PASSWORD_CONFIRMATION_CHANGE,
	PHONE_NUMBER_CHANGE,
	PHONE_NUMBER_SUBMIT,
	REQUEST_PASSWORD_RESET_EMAIL,
	RESET_MACHINE,
	SIGN_IN_WITH_EMAIL_AND_PASSWORD,
	SIGN_IN_WITH_FACEBOOK,
	SIGN_IN_WITH_GITHUB,
	SIGN_IN_WITH_GOOGLE,
	SIGN_UP_WITH_EMAIL_AND_PASSWORD,
	USER_DATA_EDITION_SUBMIT,
	USER_PASSWORD_SUBMIT,
	RESET_AUTH_ERRORS
} from './auth_actions';
import { isValidEmail } from '@planity/helpers';
import { FORGOTTEN_PASSWORD, SIGN_IN, SIGN_UP } from './form';

const { log } = actions;
const checkSendingPasswordResetEmailInputs = ({ email }) =>
	new Promise((resolve, reject) => {
		const errors = [];

		if (!email.length)
			errors.push({ name: 'email', code: MISSING_EMAIL_ERROR });

		if (!isValidEmail(email))
			errors.push({ name: 'email', code: INVALID_EMAIL_ERROR });

		if (errors.length) return reject(errors);

		resolve();
	});

const checkSignInWithEmailAndPasswordInputs = ({ email, password }) =>
	new Promise((resolve, reject) => {
		const errors = [];

		if (!email.length)
			errors.push({ name: 'email', code: MISSING_EMAIL_ERROR });

		if (!isValidEmail(email))
			errors.push({ name: 'email', code: INVALID_EMAIL_ERROR });

		if (!password.length)
			errors.push({ name: 'password', code: MISSING_PASSWORD_ERROR });

		if (errors.length) return reject(errors);

		resolve();
	});

const checkSignUpWithEmailAndPasswordInputs = ({
	email,
	password,
	phoneNumber,
	cguAcceptance,
	phoneNumberFormat = true
}) =>
	new Promise((resolve, reject) => {
		const errors = [];
		if (!phoneNumber.length && phoneNumberFormat)
			errors.push({ name: 'phoneNumber', code: MISSING_PHONE_NUMBER_ERROR });

		if (!phoneNumber.length && !phoneNumberFormat)
			errors.push({ name: 'phoneNumber', code: INVALID_FORMAT_ERROR });

		if (!email.length)
			errors.push({ name: 'email', code: MISSING_EMAIL_ERROR });

		if (!isValidEmail(email))
			errors.push({ name: 'email', code: INVALID_FORMAT_ERROR });

		if (!password.length)
			errors.push({ name: 'password', code: MISSING_PASSWORD_ERROR });

		if (password.length && password.length < 6)
			errors.push({ name: 'password', code: INVALID_PASSWORD_ERROR });

		if (!cguAcceptance)
			errors.push({
				name: 'cguAcceptance',
				code: MUST_ACCEPT_CONDITIONS_ERROR
			});

		if (errors.length) return reject(errors);

		resolve();
	});

const checkUserDataEdition = ({
	phoneNumber,
	email,
	emailHasNotBeenShared,
	isPro,
	firstName,
	lastName
}) =>
	new Promise((resolve, reject) => {
		const errors = [];
		if (!isPro && !phoneNumber.length)
			errors.push({ name: 'phoneNumber', code: MISSING_PHONE_NUMBER_ERROR });

		if (emailHasNotBeenShared) {
			if (!email || !email.length)
				errors.push({ name: 'email', code: MISSING_EMAIL_ERROR });
			if (!isValidEmail(email))
				errors.push({ name: 'email', code: INVALID_EMAIL_ERROR });
		}

		if (!firstName.length)
			errors.push({ name: 'firstName', code: MISSING_FIRST_NAME_ERROR });

		if (!lastName.length)
			errors.push({ name: 'lastName', code: MISSING_LAST_NAME_ERROR });

		if (errors.length) return reject(errors);

		resolve();
	});

/**
 * @deprecated
 * @param {*}
 * @returns
 */
const checkPasswordInput = ({ password }) =>
	new Promise((resolve, reject) => {
		if (!password.length >= 6)
			return reject([{ code: INVALID_PASSWORD_ERROR }]);

		resolve();
	});

const checkPhoneNumberInput = ({ phoneNumber }) =>
	new Promise((resolve, reject) => {
		if (!phoneNumber.length)
			return reject([
				{ name: 'phoneNumber', code: MISSING_PHONE_NUMBER_ERROR }
			]);

		resolve();
	});

const idleInputChange = {
	[EMAIL_CHANGE]: {
		actions: 'setEmail'
	},
	[PASSWORD_CHANGE]: {
		actions: 'setPassword'
	},
	[PASSWORD_CONFIRMATION_CHANGE]: {
		actions: 'setPasswordConfirmation'
	},
	[PHONE_NUMBER_CHANGE]: {
		actions: 'setPhoneNumber'
	},
	[FIRST_NAME_CHANGE]: {
		actions: 'setFirstName'
	},
	[LAST_NAME_CHANGE]: {
		actions: 'setLastName'
	},
	[CGU_ACCEPTANCE_CHANGE]: {
		actions: 'setCGUAcceptance'
	},
	[SIGN_IN_WITH_EMAIL_AND_PASSWORD]: {
		target: 'checkingSignInWithEmailAndPasswordInputs'
	},
	[SIGN_UP_WITH_EMAIL_AND_PASSWORD]: {
		actions: 'resetFromSMSVerificationModalClosing',
		target: 'checkingSignUpWithEmailAndPasswordInputs'
	},
	[SIGN_IN_WITH_GOOGLE]: {
		target: 'signingInWithGoogle'
	},
	[SIGN_IN_WITH_FACEBOOK]: {
		target: 'signingInWithFacebook'
	},
	[SIGN_IN_WITH_GITHUB]: {
		target: 'signingInWithGithub'
	},
	[REQUEST_PASSWORD_RESET_EMAIL]: {
		target: 'checkingSendingPasswordResetEmailInputs'
	},
	[CHECK_SUBSCRIPTION_STEP]: [
		{
			cond: 'isSecondStep',
			target: 'askingMissingData'
		},
		{
			cond: 'isMissingPhoneNumber',
			target: 'missingPhoneNumber'
		}
	],
	[RESET_MACHINE]: {
		target: 'idle',
		actions: 'resetState'
	},
	[RESET_AUTH_ERRORS]: {
		actions: 'clearError'
	}
};
export const createAuthMachine = ({
	email = '',
	password = '',
	error = null,
	errors = [],
	skipVerificationSMS = false,
	skipPasswordConfirmation = false,
	onSignUp = null,
	requireIsDev = false,
	hideLog = false,
	fromSocial = null,
	userId = '',
	lastName = '',
	firstName = '',
	phoneNumber = '',
	publishUserSignedUp = null,
	locale = DEFAULT_LOCALE
}) =>
	createMachine(
		{
			id: 'authMachine',
			initial: 'transient',
			context: {
				userId,
				email,
				password,
				passwordConfirmation: '',
				firstName,
				lastName,
				phoneNumber,
				phoneNumberFormat: true,
				cguAcceptance: false,
				isPro: null,
				pendingCredential: null,
				error,
				errors,
				warning: null,
				skipVerificationSMS,
				onSignUp,
				skipPasswordConfirmation,
				requireIsDev,
				hideLog,
				emailHasNotBeenShared: false,
				success: null,
				redirection: null,
				fromSocial,
				fromSMSVerificationModalClosing: false,
				publishUserSignedUp
			},
			on: {
				SIGN_OUT: {
					actions: 'resetState',
					target: 'idle'
				}
			},
			states: {
				transient: {
					entry: 'logState',
					always: [
						{
							target: 'missingPhoneNumber',
							cond: 'isMissingPhoneNumber'
						},
						{
							target: 'askingMissingData',
							cond: 'isSecondStep'
						},
						{ target: 'idle' }
					]
				},
				idle: {
					entry: ['logState', 'clearRedirection'],
					on: idleInputChange
				},
				signingInWithFacebook: {
					entry: ['clearMessages', 'logState'],
					on: idleInputChange,
					invoke: {
						id: 'signInWithFacebook',
						src: () => signInWithProvider('facebook.com'),
						onError: [
							{
								cond: 'accountExistsWithDifferentCredential',
								target: 'fetchingDifferentAccounts',
								actions: [
									'setPendingCredential',
									'setProvidedUserFromFirebase',
									'logData'
								]
							},
							{
								actions: 'setError',
								target: 'idle'
							}
						],
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromFacebook', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				signingInWithGithub: {
					entry: ['clearMessages', 'logState'],
					on: idleInputChange,
					invoke: {
						id: 'signInWithGithub',
						src: () => signInWithProvider('github.com'),
						onError: [
							{
								cond: 'accountExistsWithDifferentCredential',
								target: 'fetchingDifferentAccounts',
								actions: ['setPendingEmail', 'setPendingCredential']
							},
							{
								actions: 'setError',
								target: 'idle'
							}
						],
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromGithub', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				signingInWithGoogle: {
					entry: ['clearMessages', 'logState'],
					on: idleInputChange,
					invoke: {
						id: 'signInWithGoogle',
						src: () => signInWithProvider('google.com'),
						onError: [
							{
								cond: 'accountExistsWithDifferentCredential',
								target: 'fetchingDifferentAccounts',
								actions: ['setPendingEmail', 'setPendingCredential']
							},
							{
								actions: 'setError',
								target: 'idle'
							}
						],
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromGoogle', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				fetchingDifferentAccounts: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'fetchDifferentAccounts',
						src: fetchDifferentAccounts,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: [
							{
								cond: 'signInMethodWasEmailPassword',
								target: 'askingUserPassword'
							},
							{
								cond: 'otherProviderIsGoogle',
								target: 'askingUserToConnectWithGoogle'
							},
							{
								cond: 'otherProviderIsFacebook',
								target: 'askingUserToConnectWithFacebook'
							},
							{
								cond: 'otherProviderIsGithub',
								target: 'askingUserToConnectWithGithub'
							},
							{
								target: 'checkingUserData'
							}
						]
					}
				},
				askingUserToConnectWithGoogle: {
					entry: ['clearError', 'logState'],
					on: {
						[SIGN_IN_WITH_GOOGLE]: {
							target: 'signingInWithGoogleAndMergeAccount'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				signingInWithGoogleAndMergeAccount: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'signingInWithProviderAndMergeAccount',
						src: context =>
							signInWithProviderAndMergeAccount(context, 'google.com'),
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromGoogle', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				askingUserToConnectWithFacebook: {
					entry: ['clearError', 'logState'],
					on: {
						[SIGN_IN_WITH_FACEBOOK]: {
							target: 'signingInWithFacebookAndMergeAccount'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				signingInWithFacebookAndMergeAccount: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'signingInWithProviderAndMergeAccount',
						src: context =>
							signInWithProviderAndMergeAccount(context, 'facebook.com'),
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromFacebook', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				askingUserToConnectWithGithub: {
					entry: ['clearError', 'logState'],
					on: {
						[SIGN_IN_WITH_GITHUB]: {
							target: 'signingInWithGithubAndMergeAccount'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				signingInWithGithubAndMergeAccount: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'signingInWithProviderAndMergeAccount',
						src: context =>
							signInWithProviderAndMergeAccount(context, 'github.com'),
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: {
							actions: ['setUserId', 'setProvidedUserFromGithub', 'logData'],
							target: 'checkingUserData'
						}
					}
				},
				askingUserPassword: {
					entry: 'logState',
					on: {
						[PASSWORD_CHANGE]: {
							actions: 'setPassword'
						},
						[USER_PASSWORD_SUBMIT]: {
							target: 'checkingPasswordInput'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				checkingPasswordInput: {
					entry: ['clearError', 'sanitizeInputs', 'logState'],
					invoke: {
						id: 'checkPasswordInput',
						src: checkPasswordInput,
						onError: {
							actions: 'setError',
							target: 'askingUserPassword'
						},
						onDone: 'getCredentials'
					}
				},
				getCredentials: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'signInWithEmailAndPassword',
						src: signInWithEmailAndPassword,
						onError: {
							actions: 'setError',
							target: 'askingUserPassword'
						},
						onDone: {
							target: 'mergingEmailAndPasswordWithProvider'
						}
					}
				},
				mergingEmailAndPasswordWithProvider: {
					entry: ['clearError', 'sanitizeInputs', 'logState'],
					invoke: {
						id: 'mergeEmailAndPasswordWithProvider',
						src: mergeEmailAndPasswordWithProvider,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: 'checkingUserData'
					}
				},
				checkingUserData: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'retrieveUserData',
						src: retrieveUserData,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: [
							{
								cond: 'noMissingData',
								actions: ['setDatabaseUser', 'setIsNotPro'],
								target: 'success'
							},
							{
								cond: 'noUserFound',
								target: 'checkingProData'
							},
							{
								actions: ['setDatabaseUser', 'setIsNotPro'],
								target: 'setTemporaryUserInDatabase'
							}
						]
					}
				},
				setTemporaryUserInDatabase: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'createTemporaryUserInDatabase',
						src: createUserInDatabase,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: [
							{
								cond: 'isMissingPhoneNumber',
								target: 'missingPhoneNumber'
							},
							{
								actions: assign({ redirection: SIGN_UP }),
								target: 'askingMissingData'
							}
						]
					},
					exit: ['logLinkToUserInDatabase']
				},
				checkingProData: {
					entry: ['clearError', 'logState'],
					invoke: {
						id: 'retrieveProData',
						src: retrieveProData,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: [
							{
								cond: 'noUserFound',
								actions: 'setIsNotPro',
								target: 'setTemporaryUserInDatabase'
							},
							{
								actions: ['setDatabaseUser', 'setIsPro'],
								target: 'success'
							}
						]
					}
				},
				checkingSignInWithEmailAndPasswordInputs: {
					entry: ['clearMessages', 'clearError', 'logState'],
					invoke: {
						id: 'checkSignInWithEmailAndPasswordInputs',
						src: checkSignInWithEmailAndPasswordInputs,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: 'signingInWithEmailAndPassword'
					}
				},
				signingInWithEmailAndPassword: {
					entry: ['clearMessages', 'logState'],
					invoke: {
						id: 'signInWithEmailAndPassword',
						src: signInWithEmailAndPassword,
						onError: [
							{
								actions: ['setError'],
								target: 'idle'
							}
						],
						onDone: [
							{
								actions: 'setUserId',
								target: 'checkingUserData'
							}
						]
					}
				},
				checkingSendingPasswordResetEmailInputs: {
					entry: ['clearError', 'clearMessages', 'logState'],
					invoke: {
						id: 'checkSendingPasswordResetEmailInputs',
						src: checkSendingPasswordResetEmailInputs,
						onError: {
							actions: ['setError'],
							target: 'idle'
						},
						onDone: 'sendingPasswordResetEmail'
					}
				},
				sendingPasswordResetEmail: {
					entry: ['clearError', 'clearMessages', 'logState'],
					invoke: {
						id: 'sendPasswordResetEmail',
						src: sendPasswordResetEmail,
						onError: {
							actions: ['setError'],
							target: 'idle'
						},
						onDone: {
							actions: assign({
								redirection: FORGOTTEN_PASSWORD,
								success: 'FORGOTTEN_PASSWORD'
							}),
							target: 'idle'
						}
					}
				},
				checkingSignUpWithEmailAndPasswordInputs: {
					entry: [
						'clearError',
						'setRecaptchaToken',
						'sanitizeInputs',
						'logState'
					],
					invoke: {
						id: 'checkSignUpWithEmailAndPasswordInputs',
						src: checkSignUpWithEmailAndPasswordInputs,
						onError: {
							actions: ['setError'],
							target: 'idle'
						},
						onDone: 'signingUpWithEmailAndPassword'
					}
				},
				signingUpWithEmailAndPassword: {
					entry: [
						'clearMessages',
						'sanitizeInputs',
						'logState',
						'clearRedirection'
					],
					invoke: {
						id: 'createUserWithEmailAndPassword',
						src: createUserWithEmailAndPassword,
						onError: [
							{
								// @deprecated Social Login
								target: 'checkOtherProviders',
								cond: 'emailInUseError',
								actions: assign({ redirection: SIGN_IN })
							},
							{
								actions: ['setError'],
								target: 'idle'
							}
						],
						onDone: [
							{
								cond: 'skipVerificationSMS',
								actions: ['setUserId', 'onSignUp'],
								target: 'creatingUserInDatabase'
							},
							{
								actions: ['setUserId'],
								target: 'settingUserPhoneInDatabase'
							}
						]
					}
				},
				// When there's already this email address linked to an account, check if the account has been linked to facebook in the first place, with no password set for regular connection
				// Otherwise, try to sign in the user instead
				checkOtherProviders: {
					entry: 'logState',
					invoke: {
						id: 'checkingOtherProviders',
						src: checkingOtherProviders,
						onError: [
							{
								actions: 'setError',
								target: 'signingInWithEmailAndPassword'
							}
						],
						onDone: {
							target: 'idle',
							actions: 'setError'
						}
					}
				},
				settingUserPhoneInDatabase: {
					entry: ['logState'],
					invoke: {
						id: 'setUserPhoneInDatabase',
						src: setUserPhoneInDatabase,
						onError: {
							actions: 'setError',
							target: 'missingPhoneNumber'
						},
						onDone: [
							{
								cond: 'lambdaReturnedAnError',
								actions: ['setLambdaError', 'clearEmailHasNotBeenShared'],
								target: 'idle'
							},
							{
								target: 'checkingPhoneNumberConfirmation'
							}
						]
					},
					exit: ['logLinkToUserInDatabase']
				},
				missingPhoneNumber: {
					entry: ['logState', 'setWarning'],
					on: {
						[PHONE_NUMBER_CHANGE]: {
							actions: 'setPhoneNumber'
						},
						[PHONE_NUMBER_SUBMIT]: {
							target: 'checkingPhoneNumberInput'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				checkingPhoneNumberInput: {
					entry: ['logState', 'setWarning'],
					invoke: {
						id: 'checkPhoneNumberInput',
						src: checkPhoneNumberInput,
						onError: {
							actions: 'setError',
							target: 'missingPhoneNumber'
						},
						onDone: {
							target: 'updatingPhoneNumberInDatabase'
						}
					}
				},
				askingMissingData: {
					entry: ['logState', 'setWarning'],
					on: {
						[PHONE_NUMBER_CHANGE]: {
							actions: ['setPhoneNumber', 'sendConfirmation']
						},
						[EMAIL_CHANGE]: {
							actions: 'setEmail'
						},
						[FIRST_NAME_CHANGE]: {
							actions: 'setFirstName'
						},
						[LAST_NAME_CHANGE]: {
							actions: 'setLastName'
						},
						[USER_DATA_EDITION_SUBMIT]: {
							target: 'checkingUserDataEdition'
						},
						[BACK_TO_IDLE]: {
							target: 'idle',
							actions: 'setFromSMSVerificationModalClosing'
						},
						[RESET_MACHINE]: {
							target: 'idle',
							actions: 'resetState'
						}
					}
				},
				checkingUserDataEdition: {
					entry: ['clearError', 'sanitizeInputs', 'logState'],
					invoke: {
						id: 'checkUserDataEdition',
						src: checkUserDataEdition,
						onError: {
							actions: 'setError',
							target: 'askingMissingData'
						},
						onDone: [
							{
								cond: 'fromSocial',
								target: 'checkingPhoneNumberConfirmation'
							},
							{
								target: 'updatingUserInDatabase'
							}
						]
					}
				},
				checkingPhoneNumberConfirmation: {
					entry: 'logState',
					invoke: {
						id: 'invokePhoneNumberVerificationRecaptchaProtected',
						src: (context, event) =>
							invokePhoneNumberVerificationRecaptchaProtected(context, event, {
								locale
							}),
						onError: {
							actions: ['setError', 'clearEmailHasNotBeenShared'],
							target: 'idle'
						},
						onDone: [
							{
								cond: 'lambdaReturnedAnError',
								actions: ['setLambdaError', 'clearEmailHasNotBeenShared'],
								target: 'idle'
							},
							{
								cond: 'isSecondStep',
								target: 'askingMissingData'
							},
							{
								cond: 'noMissingData',
								target: 'success'
							}
						]
					}
				},

				creatingUserInDatabase: {
					// ⚠️ Ensure this is the last state before final state.
					// The auth_provider checks the user node to say if a user is logged in
					// so as long as there is no user data in database, we can do checks and stuff
					entry: ['logState', 'clearEmailHasNotBeenShared'],
					invoke: {
						id: 'createUserInDatabase',
						src: createUserInDatabase,
						onError: {
							actions: ['setError'],
							target: 'idle'
						},
						onDone: {
							target: 'success'
						}
					}
				},
				updatingPhoneNumberInDatabase: {
					entry: ['logState', 'clearRedirection'],
					invoke: {
						id: 'setUserPhoneInDatabase',
						src: setUserPhoneInDatabase,
						onError: {
							target: 'idle',
							actions: ['setError']
						},
						onDone: {
							target: 'checkingPhoneNumberConfirmation'
						}
					}
				},
				updatingUserInDatabase: {
					entry: ['logState', 'clearRedirection'],
					invoke: {
						id: 'updateMissingFields',
						src: updateMissingFields,
						onError: {
							target: 'idle',
							actions: ['setError']
						},
						onDone: [
							{
								cond: 'noMissingData',
								target: 'success'
							},
							{
								actions: assign({ redirection: SIGN_UP }),
								target: 'askingMissingData'
							}
						]
					}
				},
				success: {
					entry: ['clearError', 'logState', 'logLinkToUserInDatabase'],
					type: 'final'
				}
			}
		},
		{
			actions: {
				setUserId: assign({
					userId: (_, { data }) => data && data.user && data.user.uid
				}),
				logData: log((context, event) => event.data, 'Logging received data'),
				setProvidedUserFromGoogle: assign({
					email: (_, { data }) => data && data.user && data.user.email,
					firstName: (_, { data }) => {
						try {
							return data.additionalUserInfo.profile.given_name;
						} catch (e) {
							return '';
						}
					},
					lastName: (_, { data }) => {
						try {
							return data.additionalUserInfo.profile.family_name;
						} catch (e) {
							return '';
						}
					},
					phoneNumber: (_, { data }) => {
						try {
							return data.user.phoneNumber || '';
						} catch (e) {
							return '';
						}
					}
				}),
				setProvidedUserFromFacebook: assign({
					emailHasNotBeenShared: (_, { data }) =>
						!!(!data || !data.user || !data.user.email),
					email: (_, { data }) => data && data.user && (data.user.email || ''),
					firstName: (_, { data }) => {
						try {
							return data.additionalUserInfo.profile.first_name;
						} catch (e) {
							return '';
						}
					},
					lastName: (_, { data }) => {
						try {
							return data.additionalUserInfo.profile.last_name;
						} catch (e) {
							return '';
						}
					},
					phoneNumber: (_, { data }) => {
						try {
							return data.user.phoneNumber || '';
						} catch (e) {
							return '';
						}
					},
					fromSocial: true
				}),
				setProvidedUserFromGithub: assign({
					email: (_, { data }) =>
						data && data.user && data.user.email ? data.user.email : null,
					firstName(_, { data }) {
						try {
							return data.additionalUserInfo.profile.name.split(' ')[0];
						} catch (e) {
							return '';
						}
					},
					lastName: (_, { data }) => {
						try {
							return data.additionalUserInfo.profile.name
								.split(' ')
								.slice(1)
								.join(' ');
						} catch (e) {
							return '';
						}
					}
				}),
				setProvidedUserFromFirebase: assign({
					email: (_, { data }) => (data && data.email) || ''
				}),
				setDatabaseUser: assign({
					email: ({ email }, { data }) =>
						data && data.email ? data.email : email,
					firstName: ({ firstName }, { data }) =>
						(data && data.firstName) || firstName,
					lastName: ({ lastName }, { data }) =>
						(data && data.lastName) || lastName,
					phoneNumber: ({ phoneNumber }, { data }) =>
						data && data.phone ? data.phone : phoneNumber || ''
				}),
				setNames: assign({
					firstName: ({ firstName }, { data }) =>
						data && data.firstName ? data.firstName : firstName,
					lastName: ({ lastName }, { data }) =>
						data && data.lastName ? data.lastName : lastName
				}),
				setEmail: assign({
					email: (_, { email }) => email
				}),
				setPassword: assign({
					password: (_, { password }) => password
				}),
				setPasswordConfirmation: assign({
					passwordConfirmation: (_, { passwordConfirmation }) =>
						passwordConfirmation
				}),
				setPhoneNumber: assign({
					phoneNumber: (_, { phoneNumber }) => phoneNumber || '',
					phoneNumberFormat: (_, { phoneNumberFormat }) => phoneNumberFormat
				}),
				setFirstName: assign({
					firstName: (_, { firstName }) => firstName
				}),
				setLastName: assign({
					lastName: (_, { lastName }) => lastName
				}),
				setRecaptchaToken: assign({
					token: (_, { token }) => token || ''
				}),
				setCGUAcceptance: assign({
					cguAcceptance: (_, { cguAcceptance }) => cguAcceptance
				}),
				setIsPro: assign({
					isPro: true
				}),
				setIsNotPro: assign({
					isPro: false
				}),
				setPendingCredential: assign({
					pendingCredential: (_, { data }) => data.credential
				}),
				setPendingEmail: assign({
					email: (_, { data }) => data.email
				}),
				setError: assign({
					error: (_, { data }) => {
						if (Array.isArray(data)) {
							if (!data?.length) return null;

							if (
								data.find(({ code }) => code === MUST_ACCEPT_CONDITIONS_ERROR)
							)
								return MUST_ACCEPT_CONDITIONS_ERROR;

							if (
								data.find(({ code }) =>
									[
										INVALID_FORMAT_ERROR,
										INVALID_PASSWORD_ERROR,
										INVALID_EMAIL_ERROR
									].includes(code)
								)
							)
								return MISSING_ALL_INPUTS_ERROR;

							if (
								data.find(({ code }) => code === MISSING_EMAIL_ERROR) ||
								data.find(({ code }) => code === MISSING_PASSWORD_ERROR)
							)
								return MISSING_ALL_INPUTS_ERROR;

							if (data.find(({ code }) => code === MISSING_EMAIL_ERROR))
								return MISSING_EMAIL_ERROR;

							if (data.find(({ code }) => code === MISSING_PASSWORD_ERROR))
								return MISSING_PASSWORD_ERROR;

							if (
								data.find(({ code }) => code === MISSING_LAST_NAME_ERROR) ||
								data.find(({ code }) => code === MISSING_FIRST_NAME_ERROR)
							)
								return MISSING_ALL_INPUTS_ERROR;

							if (data.find(({ code }) => code === MISSING_PHONE_NUMBER_ERROR))
								return MISSING_PHONE_NUMBER_ERROR;

							if (data.find(({ code }) => code === TOO_MANY_ATTEMPTS_ERROR))
								return TOO_MANY_ATTEMPTS_ERROR;
						} else {
							if (data?.code) {
								if (data.code === WRONG_PASSWORD_ERROR && process.env.WIDGET) {
									return WRONG_PASSWORD_ERROR_WIDGET;
								}
								if (
									data.code === WRONG_PASSWORD_ERROR ||
									data.code === EMAIL_IN_USE_ERROR ||
									data.code === USER_NOT_FOUND_ERROR
								) {
									return MISSING_ALL_INPUTS_ERROR;
								}
							}
						}

						// TODO Faire ça bien
						return 'defaultError';
					},
					errors: (_, { data }) => {
						if (data?.code && !Array.isArray(data)) {
							if (data?.code === WRONG_PASSWORD_ERROR) {
								data = [
									{
										name: 'password',
										code: data?.code
									}
								];
							}
							if (data?.code === EMAIL_IN_USE_ERROR) {
								data = [
									{
										name: 'email',
										code: data?.code
									}
								];
							}
							if (data?.code === USER_NOT_FOUND_ERROR) {
								data = [
									{
										name: 'email',
										code: data?.code
									}
								];
							}
						}
						return data;
					}
				}),
				setWarning: assign({
					warning: 'FILL_MISSING_DATA'
				}),
				setSuccess: assign({
					success: (_, { data }) => data
				}),
				setLambdaError: assign({
					error: (_, { data }) => data.errorMessage
				}),
				clearMessages: assign({
					error: null,
					errors: [],
					warning: null,
					success: null
				}),
				clearError: assign({
					error: null,
					errors: []
				}),
				clearWarning: assign({
					warning: null
				}),
				clearSuccess: assign({
					success: null
				}),
				clearEmailHasNotBeenShared: assign({
					emailHasNotBeenShared: false
				}),
				clearRedirection: assign({
					redirection: null
				}),
				onSignUp: ({ userId, onSignUp, publishUserSignedUp }) => {
					onSignUp && typeof onSignUp === 'function' && onSignUp(userId);
					publishUserSignedUp &&
						typeof publishUserSignedUp === 'function' &&
						publishUserSignedUp();
				},
				sendConfirmation: ({ phoneNumber, locale, token }) =>
					phoneNumber &&
					invokePhoneNumberVerificationRecaptchaProtected({
						phoneNumber,
						locale,
						token
					}),
				logLinkToUserInDatabase: ({ userId, isPro }) =>
					process.env.NODE_ENV === 'development' &&
					console.log(
						`User in database %chttps://planity-lab.firebaseio.com/${
							isPro ? 'pros' : 'users'
						}/${userId}`,
						'background: MediumVioletRed; color: white; font-weight: bold'
					),
				sanitizeInputs: assign({
					firstName: ({ firstName }) => (firstName || '').trim(),
					lastName: ({ lastName }) => (lastName || '').trim(),
					email: ({ email }) => (email || '').trim().toLowerCase(),
					phoneNumber: ({ phoneNumber }) => (phoneNumber || '').trim()
				}),
				logState: (
					{ password, passwordConfirmation, value, ...context },
					_,
					{ state }
				) => {
					if (process.env.PLANITY_ENV === 'lab' && !hideLog) {
						console.log(
							`🌟 Machine dans l'état : %c${state.value}`,
							'background: #a0e7a0; color:black;font-weight: bold'
						);
						console.log({
							...context,
							password: password ? password.replace(/./g, '*') : undefined,
							passwordConfirmation: passwordConfirmation
								? passwordConfirmation.replace(/./g, '*')
								: undefined
						});
					}
				},
				resetState: assign({
					firstName: '',
					lastName: '',
					email: '',
					phoneNumber: '',
					phoneNumberFormat: true,
					userId: '',
					password: '',
					passwordConfirmation: '',
					cguAcceptance: false,
					isPro: null,
					pendingCredential: null,
					error: null,
					errors: [],
					warning: null,
					emailHasNotBeenShared: false
				}),
				setFromSMSVerificationModalClosing: assign({
					fromSMSVerificationModalClosing: (
						_,
						{ fromSMSVerificationModalClosing }
					) => fromSMSVerificationModalClosing
				}),
				resetFromSMSVerificationModalClosing: assign({
					fromSMSVerificationModalClosing: false
				})
			},
			guards: {
				emailInUseError: (_, { data }) =>
					!!data && data.code === EMAIL_IN_USE_ERROR,
				skipVerificationSMS: ({ skipVerificationSMS }) => !!skipVerificationSMS,
				fromSocial: ({ fromSocial }) => !!fromSocial,
				lambdaReturnedAnError: (_, { data }) => data && data.errorMessage,
				userAlreadyHadAnAccount: (_, { data }) =>
					!(
						data &&
						data.additionalUserInfo &&
						data.additionalUserInfo.isNewUser
					),
				noMissingData: (_, { data }) =>
					!!data &&
					!!data.email &&
					!!data.phone &&
					!!data.firstName &&
					!!data.lastName,
				isSecondStep: context =>
					!!context &&
					!!context.userId &&
					!!context.email &&
					!!context.phoneNumber,
				isMissingPhoneNumber: context =>
					!!context &&
					!!context.userId &&
					!!context.email &&
					!context.phoneNumber,
				noUserFound: (_, { data }) => data === null,
				accountExistsWithDifferentCredential: (_, { data: error }) =>
					error.code === 'auth/account-exists-with-different-credential',
				signInMethodWasEmailPassword: (_, { data }) => data[0] === 'password',
				otherProviderIsGoogle: (_, { data }) => data[0] === 'google.com',
				otherProviderIsFacebook: (_, { data }) => data[0] === 'facebook.com',
				otherProviderIsGithub: (_, { data }) => data[0] === 'github.com',
				hasOtherProviders: (_, { data }) => !!data.length,
				requireIsDev: ({ requireIsDev }) => requireIsDev,
				emailHasNotBeenShared: (_, { data }) =>
					!data || !data.user || !data.user.email
			}
		}
	);
