import {
	AVAILABLE_LOCALES,
	useLocalization,
	useLocalizedRoutes
} from '@planity/localization';
import { FirebaseStatusContext } from '@planity/context';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { usePrevious } from '@planity/helpers';
import {
	CatchAllPage,
	ComplainRejectedUserReview,
	CookiePolicy,
	CookiesPage,
	CookiesManagement,
	ForgottenPasswordPage,
	Impressum,
	LandingPage,
	LegalPage,
	MaintenancePage,
	ManageAppointmentPage,
	MyAccountPage,
	PrivacyPolicy,
	ResetPasswordPage,
	SearchBusinessPage,
	SearchPlacePage,
	UnmovableAppointmentPage,
	IllicitContentPolicy
} from './components';

export function Routes() {
	const { website } = useContext(FirebaseStatusContext);
	const { routes } = useLocalizedRoutes();
	const { locale } = useLocalization();

	// Looks like : (/fr-FR|/de-DE|/fr-BE/nl-BE)
	// That's a special way of handling regex by react-router-dom v5.x
	const matchLocale = `(${AVAILABLE_LOCALES.map(locale => `/${locale}`).join(
		'|'
	)})`;

	if (website) {
		return (
			<Route
				path={[`:locale${matchLocale}`, '/']}
				render={({ location: { pathname, search }, history, match }) => {
					// Remove trailing slash, I think
					const shouldRedirect = pathname !== '/' && !!pathname.match(/\/+$/);
					if (shouldRedirect) {
						return <Redirect to={pathname.replace(/\/+$/, '')} />;
					}
					if ((match.params.locale || '').match(/^\/?fr-FR/)) {
						console.log('Removing fr-FR from URL');
						return <Redirect to={pathname.replace(/^\/?fr-FR/, '')} />;
					}
					const isGermany = ['/de-DE'].includes(match.params.locale);
					const isFrench = ['fr-FR'].includes(locale);
					const isFrBE = ['fr-BE'].includes(locale);
					/*
					 * Checking if there is a query string `lang` in the location and if we're on a resetPassword route
					 * if the query string is present, we need to parse url to create a new one with the right locale
					 * (this is handled in the middleware for the website, but we need to handle it in the
					 * CatchAllPage > WebviewRedirections > WebviewRedirectResetPassword component for the webview to be correctly redirect)
					 */
					const isFromUnhandledResetPasswordLocation =
						pathname.match(new RegExp(routes.resetPassword)) &&
						search.includes('lang=');
					return (
						<CustomSwitch>
							<Route
								component={LandingPage}
								exact
								path={['/', `:locale${matchLocale}`]}
								sensitive
							/>
							<Route component={LegalPage} path={routes.legal} />
							<Route component={CookiesPage} path={routes.cookies} />
							<Route
								component={CookiesManagement}
								path={routes.cookiesManagement}
							/>
							<Route
								component={ComplainRejectedUserReview}
								path={routes.complainRejectedUserReview}
							/>
							{isGermany && (
								<Route component={CookiePolicy} path={routes.cookiesPolicy} />
							)}
							<Route component={PrivacyPolicy} path={routes.privacyPolicy} />
							{isFrench && (
								<Route
									path={routes.privacyPolicyAsHTML}
									render={() => <PrivacyPolicy asHTML />}
								/>
							)}
							{(isFrench || isFrBE || isGermany) && (
								<Route
									component={IllicitContentPolicy}
									path={routes.illicitContentPolicy}
								/>
							)}

							{!isFromUnhandledResetPasswordLocation && (
								<Route
									component={ResetPasswordPage}
									path={routes.resetPassword}
								/>
							)}
							<Route
								path={routes.myAccountOrders}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								path={routes.myAccountGiftCards}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								path={routes.myAccountCures}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								path={routes.myAccountInformation}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								path={routes.myAccountChildren}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								path={[
									`${routes.myAccount}/:key?`,
									`/:locale?/${routes.myAccount}/:key?`
								]}
								render={props => <MyAccountPage history={history} {...props} />}
							/>
							<Route
								component={UnmovableAppointmentPage}
								path={routes.unmovableAppointment}
							/>
							<Route
								component={ForgottenPasswordPage}
								path={routes.forgottenPassword}
							/>
							{isGermany && (
								<Route component={Impressum} path={routes.impressum} />
							)}
							<Route
								component={SearchBusinessPage}
								path={routes.searchBusiness}
							/>
							<Route component={SearchPlacePage} path={routes.searchPlace} />
							<Route component={ManageAppointmentPage} path={'/a/:shortCode'} />
							<Route component={ManageAppointmentPage} path={'/b/:shortCode'} />
							<Route
								component={ManageAppointmentPage}
								path={['/c/:shortCode', '/:locale/c/:shortCode']}
							/>
							<Route
								component={ManageAppointmentPage}
								path={['/d/:shortCode', '/:locale/d/:shortCode']}
							/>
							<Route
								component={ManageAppointmentPage}
								path={['/e/:shortCode', '/:locale/e/:shortCode']}
							/>
							<Route
								component={ManageAppointmentPage}
								path={['/f/:shortCode', '/:locale/f/:shortCode']}
							/>
							<Route
								component={ManageAppointmentPage}
								path={['/g/:shortCode', '/:locale/g/:shortCode']}
							/>
							<Route
								component={ManageAppointmentPage}
								path={['/h/:shortCode', '/:locale/h/:shortCode']}
							/>
							<Route
								component={MaintenancePage}
								path={['/maintenance', '/:locale/maintenance']}
							/>
							<Route component={CatchAllPage} />
						</CustomSwitch>
					);
				}}
				sensitive
			/>
		);
	} else {
		return (
			<Switch>
				<Route component={MaintenancePage} />
			</Switch>
		);
	}
}

const CustomSwitch = ({ children }) => {
	// used only to clear session storage - we store tab account to be redirected on the right tab after booking
	// (all because of stripe - apiKey/countryCode)
	const location = useLocation(); // useLocation is less risky then useHistory since history is mutable
	const { routes } = useLocalizedRoutes();
	const prevLocationState = usePrevious(location?.pathname);

	useEffect(() => {
		// Condition when leaving my account (and its many variants)
		if (
			prevLocationState?.includes(routes.myAccount) &&
			!location?.pathname.includes(routes.myAccount)
		) {
			sessionStorage.removeItem('tab');
		}
	}, [prevLocationState, routes, location?.pathname]);

	return <Switch>{children}</Switch>;
};
