import { minutesFromTimeString } from './time';

const DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export function parseOpeningHours(openingHours, options = {}) {
	try {
		if (!openingHours) return null;
		const { convertToMinutes = true } = options;
		return openingHours
			.replace(/;$/, '')
			.split(/\s*;\s*/g)
			.reduce((days, rule) => {
				const ruleDays = rule.match(/([A-Z][a-z][\-,]?)+/g)[0];
				const ruleMatch = rule.match(/\d\d:\d\d-\d\d:\d\d/g);
				const ruleHours = ruleMatch
					? ruleMatch.map(hours =>
							hours
								.split('-')
								.map(t => (convertToMinutes ? minutesFromTimeString(t) : t))
					  )
					: [];
				return _daysFromRuleDays(ruleDays).reduce((allDays, day) => {
					if (!ruleHours?.length) return allDays;
					return {
						...allDays,
						[day]: ruleHours
					};
				}, days);
			}, {});
	} catch (e) {
		console.log("Error on parseOpeningHours but it's handled.", e.message);
		return null;
	}
}

function _daysFromRuleDays(daysRule) {
	if (daysRule.length === 2) return [daysRule];
	if (daysRule.match(/,/)) return daysRule.split(',');
	let bounds = daysRule.split('-');
	let indexes = bounds.map(d => DAYS.indexOf(d));
	if (indexes[0] < indexes[1]) {
		return DAYS.slice(indexes[0], indexes[1] + 1);
	} else if (indexes[0] === indexes[1]) {
		return [bounds[0]];
	} else {
		return DAYS.slice(indexes[0], DAYS.length).concat(
			DAYS.slice(0, indexes[1] + 1)
		);
	}
}
