import {
	AppointmentSourceProvider,
	AuthContextProvider,
	CleanLastSuccessfulAppointmentOnAuthChange,
	ClickAndCollectProvider,
	ErrorMessagesProvider,
	FormFactorContextProvider,
	LastSuccessfulAppointmentProvider,
	Localize,
	MapLoaderContextProvider,
	StripeFeesProvider,
	ViewerHeadersContextProvider,
	UserQueriesProvider,
	StripeElementsProvider,
	CrashHandler
} from '@planity/components';
import {
	AppBannerContextProvider,
	FirebaseStatusProvider,
	OverlayContextProvider,
	SearchProvider,
	UserDataProvider
} from '@planity/context';
import config from '@planity/credentials';
import { TrackGoalEvents, TrackPageviews } from '@planity/helpers/analytics';
import {
	LocalizationContextProvider,
	LocalizedRoutesContextProvider,
	useSSR,
	DEFAULT_LANGUAGE
} from '@planity/localization';
import { ResponsiveContextProvider } from '@planity/theme';
import {
	GalleryProvider,
	ModalProvider,
	ProfileGalleryProvider,
	RedirectToCountryModal
} from '@planity/ui';
import { css } from 'glamor';
import 'glamor/reset';
import React, { StrictMode } from 'react';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { Route } from 'react-router-dom';
import {
	CacheContextProvider,
	CategoriesContextProvider
} from './components/data';
import { CookiesWarning } from './components/layout';
import FilterProvider from './components/ui/filter/context';
import { Routes } from './router';
import { RandomIdProvider } from '@planity/context/random_id';
import { ThemeContextProvider } from './providers/theme';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from '../public/styles.scss';
import { isNativeApp } from '@planity/webview';
import { ErrorPage } from './components/error_page';
import { WebviewScripts } from './components/webview_scripts';

css.global('html', {
	boxSizing: 'border-box',
	userSelect: 'none',
	WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
	textSizeAdjust: '100%'
});
css.global('*, *:before, *:after', { boxSizing: 'inherit' });
css.global('svg', { boxSizing: 'content-box' });
css.global('.mapboxgl-popup', {
	zIndex: 100
});
css.global('.grecaptcha-badge', {
	visibility: 'hidden'
});

if (process.env.PLANITY_ENV !== 'production') {
	console.log(
		`%cAPP VERSION ${process.env.VERSION}`,
		'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)'
	);
}

export const App = hot(({ formFactor, locale, initialI18nStore }) => {
	if (isNativeApp) {
		useStyles(styles);
	} else {
		const [language] = locale?.split('-') || [DEFAULT_LANGUAGE];
		useSSR(initialI18nStore, language);
	}

	return (
		<StrictMode>
			<RandomIdProvider>
				<ThemeContextProvider>
					<ViewerHeadersContextProvider>
						<LocalizationContextProvider initialLocale={locale}>
							<LocalizedRoutesContextProvider>
								<CrashHandler errorComponent={<ErrorPage />}>
									<Localize text={'meta'}>
										{({ title, description, language, homepagePreview }) => (
											<Route
												render={({ location }) => {
													const url = `https://www.${
														config.HOST
													}${cleanLocation(location)}`;
													const hasSelfCanonicalURL = !url.match(/\/gp_\S+/);
													const canonicalURL = hasSelfCanonicalURL
														? url
														: url.split('/gp_')[0];
													return (
														<Helmet defaultTitle={'Planity'}>
															<meta content={url} property={'og:url'} />
															<meta content={title} property={'og:title'} />
															<meta content={'website'} property={'og:type'} />
															{!location.pathname.startsWith('/b/') && (
																<meta
																	content={homepagePreview}
																	property={'og:image'}
																/>
															)}
															<meta
																content={'1200'}
																property={'og:image:width'}
															/>
															<meta
																content={'630'}
																property={'og:image:height'}
															/>
															<meta
																content={'Planity'}
																property={'og:site_name'}
															/>
															<meta content={language} property={'og:locale'} />
															<meta
																content={description}
																property={'og:description'}
															/>
															<link href={canonicalURL} rel={'canonical'} />
														</Helmet>
													);
												}}
											/>
										)}
									</Localize>
									<OverlayContextProvider>
										<AppBannerContextProvider>
											<StripeFeesProvider>
												<StripeElementsProvider>
													<ClickAndCollectProvider>
														<AppointmentSourceProvider>
															<ResponsiveContextProvider>
																<FormFactorContextProvider
																	formFactor={formFactor}
																>
																	<ErrorMessagesProvider>
																		<CacheContextProvider>
																			<AuthContextProvider skipIsAdmin>
																				<SearchProvider>
																					<FilterProvider>
																						<CategoriesContextProvider>
																							<MapLoaderContextProvider>
																								<LastSuccessfulAppointmentProvider>
																									<CleanLastSuccessfulAppointmentOnAuthChange>
																										<UserDataProvider>
																											<UserQueriesProvider>
																												<TrackGoalEvents>
																													<TrackPageviews>
																														<FirebaseStatusProvider>
																															<ModalProvider>
																																<ProfileGalleryProvider>
																																	<GalleryProvider>
																																		<RedirectToCountryModal />
																																		<Routes />
																																		<WebviewScripts />
																																	</GalleryProvider>
																																</ProfileGalleryProvider>
																															</ModalProvider>
																														</FirebaseStatusProvider>
																													</TrackPageviews>
																												</TrackGoalEvents>
																											</UserQueriesProvider>
																										</UserDataProvider>
																									</CleanLastSuccessfulAppointmentOnAuthChange>
																								</LastSuccessfulAppointmentProvider>
																							</MapLoaderContextProvider>
																						</CategoriesContextProvider>
																					</FilterProvider>
																				</SearchProvider>
																				<CookiesWarning />
																			</AuthContextProvider>
																		</CacheContextProvider>
																	</ErrorMessagesProvider>
																</FormFactorContextProvider>
															</ResponsiveContextProvider>
														</AppointmentSourceProvider>
													</ClickAndCollectProvider>
												</StripeElementsProvider>
											</StripeFeesProvider>
										</AppBannerContextProvider>
									</OverlayContextProvider>
								</CrashHandler>
							</LocalizedRoutesContextProvider>
						</LocalizationContextProvider>
					</ViewerHeadersContextProvider>
				</ThemeContextProvider>
			</RandomIdProvider>
		</StrictMode>
	);
});

function cleanLocation({ pathname }) {
	return (pathname || '').replace(/\/+$/, '').replace(/\?$/, '');
}
