import React, { useEffect, useRef, useState } from 'react';
import { Service } from './service';
import { sanitizeName } from '@planity/helpers';
import { Button, Icon } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './service_set.module.scss';
import { breakpoints, Match } from '@planity/theme';
import { useTranslation } from '@planity/localization';
import RichText from '../../ui/rich_text';
import { useTheme } from '@planity/context';

const COLLAPSED_CHILDREN_SIZE = 5; // Default max size of collapsed list

export default function ServiceSet({
	serviceSet,
	business,
	dispatch,
	redirectTo,
	initCollapseMobile = true,
	isDark,
	isGiftVoucher,
	servicesNotCollapsed,
	setIndex
}) {
	if (!serviceSet || !serviceSet.children) return null;

	useStyles(styles);
	const { t } = useTranslation();
	const serviceSetSize = serviceSet.children.length;

	const [limit, setLimit] = useState(COLLAPSED_CHILDREN_SIZE);
	const [isLimited, setIsLimited] = useState(true);
	const [isCollapsed, setIsCollapsed] = useState(
		servicesNotCollapsed ? false : initCollapseMobile
	);
	const { isDarkThemeWidget } = useTheme();
	const cx = classNames.bind(styles);
	const content = useRef();

	const getButtonLabel = () => {
		if (!limit) return t(`bookAppointment.showLessServices`);

		const size = serviceSetSize - limit;
		return t(`bookAppointment.showMoreServices`, {
			count: size,
			size
		});
	};

	useEffect(() => {
		const handle = () => setIsLimited(window.innerWidth >= breakpoints.tablet);
		handle();
		window.addEventListener('resize', handle);
		return () => window.removeEventListener('resize', handle);
	}, []);

	return (
		<Match query={breakpoints.simpleTabletQuery}>
			{isTablet => {
				return (
					<div
						className={cx({
							['planity_ui_item-list-element']: !(
								isTablet && process.env.WIDGET
							),
							['planity_appointment_mobile_container']: !(
								isTablet && process.env.WIDGET
							),
							businessServices: true,
							isLimited,
							isCollapsed
						})}
						id='mobile-service-item'
						itemProp={'itemListElement'}
						itemScope
						itemType={'https://schema.org/OfferCatalog'}
					>
						<h3
							className={cx('title', {
								isDark,
								isDarkThemeWidget
							})}
							onClick={() => setIsCollapsed(!isCollapsed)}
						>
							{sanitizeName(serviceSet.name)}
							<Icon
								className={styles.chevron}
								icon='ChevronRight'
								size={'medium'}
							/>
						</h3>
						{serviceSet.description ? (
							<div
								className={cx('description', {
									isDark,
									isDarkThemeWidget
								})}
							>
								<RichText text={sanitizeName(serviceSet.description)} />
							</div>
						) : (
							<div className={styles.description} />
						)}
						<div
							className={cx(
								{
									card: true,
									padding: serviceSetSize > limit,
									isCollapsed
								},
								'planity_appointment_step_wrapper'
							)}
							ref={content}
							style={{
								// Quick when not a lot of services
								transitionDuration: `${Math.min(serviceSetSize * 200, 1000)}ms`
							}}
						>
							<div className={styles.cardWrapper}>
								{serviceSet.description && (
									<div className={styles.mobileDescription}>
										<RichText text={sanitizeName(serviceSet.description)} />
									</div>
								)}
								<ul>
									{serviceSet.children.map((service, i) => {
										const isVisible = isLimited && limit ? i < limit : true;
										return (
											isVisible && (
												<Service
													business={business}
													className={styles.service}
													collapse={isCollapsed}
													dispatch={dispatch}
													index={i}
													isGiftVoucher={isGiftVoucher}
													key={service.id}
													redirectTo={redirectTo}
													service={service}
													serviceId={service.id}
													setIndex={setIndex}
												/>
											)
										);
									})}
								</ul>
								{isLimited && serviceSetSize > limit && (
									<Button
										className={`${styles.showMore} planity_ui_showMoreservices`}
										label={getButtonLabel()}
										noFocus
										type='underlined'
										onClick={() =>
											setLimit(limit ? false : COLLAPSED_CHILDREN_SIZE)
										}
									/>
								)}
							</div>
						</div>
					</div>
				);
			}}
		</Match>
	);
}
