import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import config from '@planity/credentials';
import { BreadCrumbIcon, BreadCrumbLink, BreadCrumbSpan } from '@planity/ui';
import styles from './breadcrumbs.module.scss';

export function Breadcrumbs({
	crumbs,
	crumbName,
	hasBackgroundColor,
	removePadding,
	isHiddenOnMobile,
	isHiddenOnTablet,
	className,
	addVirtualHome
}) {
	const classes = classNames.bind(styles)({
		breadcrumbs: true,
		[className]: className,
		hasBackgroundColor,
		isHiddenOnMobile,
		isHiddenOnTablet,
		removePadding
	});
	useStyles(styles);

	const location = useLocation();
	const isLast = crumbs && crumbs.length && crumbs.length - 1;
	return (
		<div className={classes}>
			<nav
				className={styles.nav}
				itemScope
				itemType={'https://schema.org/BreadcrumbList'}
			>
				{addVirtualHome && <Crumb index={1} isVirtualHome={true} />}
				{(crumbs || [])
					.concat(
						crumbName
							? {
									path: location.pathname,
									localizedName: crumbName
							  }
							: []
					)
					.map((crumb, i) => (
						// Yes, it could have been CSS
						<span className={styles.center} key={crumb.path + i}>
							{i !== 0 && <BreadCrumbIcon />}
							<Crumb {...crumb} index={i + 2} isLast={isLast === i} />
						</span>
					))}
			</nav>
		</div>
	);
}

function Crumb({ path, name, localizedName, index, isLast, isVirtualHome }) {
	const metaURL = `https://www.${config.HOST}${path}`;
	const { t } = useTranslation();
	if (isVirtualHome) {
		return (
			<span
				itemProp={'itemListElement'}
				itemScope
				itemType={'https://schema.org/ListItem'}
			>
				<meta content={index} itemProp={'position'} />
				<meta content={`https://www.${config.HOST}`} itemProp={'item'} />
				<meta content={'planity'} itemProp={'name'} />
			</span>
		);
	}
	const computedName = localizedName ? t(`nav.${localizedName}`) : name;
	return (
		<span
			itemProp={'itemListElement'}
			itemScope
			itemType={'https://schema.org/ListItem'}
		>
			{isLast ? (
				<BreadCrumbSpan
					id={`breadcrumb-span-${localizedName || name}`}
					isLast
					itemProp={'name'}
					text={computedName}
				/>
			) : (
				<BreadCrumbLink
					childItemProp='name'
					id={`breadcrumb-link-${localizedName || name}`}
					isLast={false}
					microdata={{
						itemProp: 'item',
						itemScope: true,
						itemID: metaURL
					}}
					text={computedName}
					to={path}
				/>
			)}
			<meta content={index} itemProp={'position'} />
			<meta content={metaURL} itemProp={'url'} />
			<meta content={computedName} itemProp={'name'} />
		</span>
	);
}
