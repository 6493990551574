import { isNativeApp } from '@planity/webview';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAuth, useViewerHeaders } from '@planity/components';
import { useOverlay } from '@planity/context';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './header.module.scss';
import {
	Logo,
	Button,
	Icon,
	useModal,
	RenderCountrySelectorModal
} from '@planity/ui';
import { Link, useHistory } from 'react-router-dom';
import {
	useLocalizedRoutes,
	useTranslation,
	useLocalization
} from '@planity/localization';
import { isMobileSafari } from '@planity/helpers';
import { breakpoints } from '@planity/theme';

export function Header({
	center,
	isTransparent = false,
	isInverted = false,
	navItems = [],
	isCollapsible,
	hideNavigationItems = false,
	isFullWidth,
	stickyOnDesktop = true
}) {
	useStyles(styles);
	const { isOpen, setIsOpen } = useOverlay();
	const [isScrolled, setIsScrolled] = useState(false);
	const { user, isLoading: userIsLoading } = useAuth();
	const { routes } = useLocalizedRoutes();
	const { languageName, locale } = useLocalization();
	const { t } = useTranslation();
	const history = useHistory();
	const isGermany = locale === 'de-DE';
	const { setModal } = useModal();
	const { isAndroidViewer } = useViewerHeaders();
	const queryParameterCampaign = 'utm_source=planity&utm_campaign=header';

	function goBack() {
		if (history.length <= 1) return history.push(routes.home);

		// cases where history.go(-n) seems to not work
		if (
			(isMobileSafari() && !isNativeApp) ||
			(history.location.pathname.includes('/reset-password') && isAndroidViewer)
		)
			return history.replace(routes.home);

		// Normal case
		history.goBack();
		// PLAN-9565. Webview fix, was displaying a blank page under some circumstances
		window.scroll({
			top: 1,
			behavior: 'smooth'
		});
	}

	useEffect(() => {
		if (process.env.BROWSER) {
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, []);

	const handleScroll = () => {
		setIsScrolled(() => window.scrollY > 64);
	};

	const classes = classNames.bind(styles);

	return (
		<header
			className={classes({
				header: true,
				isOpen,
				isCollapsible,
				isTransparent: isTransparent && !isScrolled,
				isInverted: isInverted && !isScrolled,
				hasBorderBottom: !hideNavigationItems || isNativeApp,
				isNativeApp,
				stickyOnDesktop: stickyOnDesktop
			})}
			onScroll={handleScroll}
		>
			<div
				className={classes({
					container: true,
					isFullWidth
				})}
			>
				{!isNativeApp && (
					<button
						id={'nav-item-burger-menu'}
						className={styles.burger}
						onClick={() => setIsOpen(!isOpen)}
						aria-label='menu'
					>
						<span />
					</button>
				)}

				{isNativeApp && history.location.pathname === routes.home && (
					<button
						className={styles.world}
						onClick={() =>
							setModal({
								content: <RenderCountrySelectorModal />,
								title: null,
								isFullHeight: false,
								hasCloseBtn: false
							})
						}
					>
						<Icon icon='World' size={'medium'} />
						<span>{languageName}</span>
						<Icon icon='ChevronDown' size={'medium'} />
					</button>
				)}

				{isNativeApp && history.location.pathname !== routes.home && (
					<div className={styles.nativeAppIcon} onClick={goBack}>
						<Icon icon='ArrowLeft' size={'medium'} />
					</div>
				)}
				<Link
					className={classes({
						logo: true,
						isInverted: isInverted && !isScrolled && !isNativeApp,
						isNativeApp
					})}
					to={routes.home}
				>
					<Logo className={styles.logoImage} id={'nav-item-planity-logo'} />
				</Link>
				<div className={styles.center}>
					{center}
					{!center && !hideNavigationItems && (
						<div className={styles.navItems}>
							{navItems?.map((navItem, i) => (
								<Link
									id={`nav-item-${i}`}
									to={navItem.link}
									key={navItem.label}
									className={classes({
										item: true,
										isInverted: isInverted && !isScrolled
									})}
									aria-current={i === navItem.isActive}
								>
									{navItem.label}
								</Link>
							))}
							{isGermany && (
								<a
									id={`nav-item-mag`}
									href={routes.mag}
									className={classes({
										item: true,
										isInverted: isInverted && !isScrolled
									})}
									rel='noreferrer'
									target='_blank'
								>
									{t('nav.mag')}
								</a>
							)}
						</div>
					)}
				</div>

				<div className={styles.right}>
					<Button
						id={'nav-item-become-partner'}
						isLoading={false}
						to={
							isGermany
								? `${routes.becomePartnerBasePath}?${queryParameterCampaign}`
								: `${routes.becomePartner}?${queryParameterCampaign}`
						}
						isExternal
						size={'default'}
						type={'secondary'}
						label={t('nav.becomePartner')}
						outlined
						className={classes({
							desktopRightButton: true,
							isNativeApp,
							becomePartner: true
						})}
						data-userisloading={userIsLoading}
						obfuscateLink
					/>
					<Button
						id={'nav-item-my-account-mobile'}
						isLoading={false}
						to={routes.myAccount}
						iconLeft={user ? 'UserCheck' : 'User'}
						size={'medium'}
						className={styles.mobileRightButton}
						obfuscateLink
					/>
					<Button
						iconLeft={user ? 'UserCheck' : 'User'}
						id={'nav-item-my-account-desktop'}
						to={routes.myAccount}
						label={t('business.myAccount')}
						size={'default'}
						className={classes({
							desktopRightButton: true,
							textEllipsis: true,
							myAccount: true
						})}
						obfuscateLink
					/>
				</div>
			</div>
		</header>
	);
}
