import React from 'react';
import classNames from 'classnames/bind';
import styles from './opening_hours.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Localize } from '@planity/components';
import { format as formatDate } from 'date-fns';
import { Card } from '@planity/ui';
import { useTranslation } from '@planity/localization';

const TODAY = formatDate(new Date(), 'EEEEEE');

export function OpeningHours({
	business,
	openingHours,
	className,
	hasTransparentBackground
}) {
	const { t } = useTranslation();
	useStyles(styles);

	const classes = classNames.bind(styles)({
		openingHours: true,
		[className]: className !== undefined,
		widget: process.env.WIDGET
	});

	if (!openingHours) return null;

	return (
		<div
			className={classes}
			content={business.openingHours}
			itemProp={'openingHours'}
		>
			<Card
				hasTransparentBackground={hasTransparentBackground}
				title={t('business.openingHoursTitle')}
			>
				<ul>
					<Localize text={'dates.week'}>
						{week =>
							Object.entries(week)
								.sort(sortByDay)
								.map(([dayKey, dayName]) => (
									<li
										className={`${styles.row} ${
											TODAY === dayKey ? styles.isCurrent : ''
										}`}
										key={dayKey}
									>
										<span className={styles.day}>{dayName}</span>
										<span
											className={`${styles.time} ${
												!openingHours[dayKey] ? styles.closed : ''
											}`}
										>
											{openingHours[dayKey]?.map((range, i) => (
												<span key={i}>
													{range.join(`${t('punctuation.spacedHyphen')}`)}
												</span>
											)) || t('business.closed')}
										</span>
									</li>
								))
						}
					</Localize>
				</ul>
			</Card>
		</div>
	);
}

const sorter = {
	Mo: 1,
	Tu: 2,
	We: 3,
	Th: 4,
	Fr: 5,
	Sa: 6,
	Su: 7
};

function sortByDay([a], [b]) {
	return sorter[a] - sorter[b];
}
